<template>
  <div class="sqs-error sqs-error--502">
    <div class="sqs-error-box">
      <img src="@/assets/developing.gif" alt="" />
      <p>{{ messge }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messge: "服务器无响应，请尝试Ctrl+F5刷新页面！",
    };
  },
};
</script>
<style lang="scss" scoped>
.sqs-error.sqs-error--502 {
  text-align: center;
  font-size: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sqs-error-box {
    width: 100%;
    text-align: center;
    p {
      font-size: 3rem;
      .sqs-error-back {
        cursor: pointer;
        color: #3366ff;
      }
    }
  }
}
</style>
